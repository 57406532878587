import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Stvrt from '../views/Stvrt.vue'
import PriJazierku from '../views/PriJazierku.vue'
import Bory1 from '../views/Bory1.vue'
import Bory2 from '../views/Bory2.vue'
import Bory3 from '../views/Bory3.vue'
import Bory4 from '../views/Bory4.vue'
import Bory5 from '../views/Bory5.vue'
import Novinky from '../views/News.vue'
import RetailZone from '../views/RetailZone.vue'
import NewsDetail from '../views/NewsDetail.vue'
import Gallery from '../views/Gallery.vue'
import Contact from '../views/Contact.vue'
import Flats from '../views/Flats.vue'
import Cookies from '../views/Cookies.vue'
import Gdpr from '../views/Gdpr.vue'
import FlatDetail from '../views/FlatDetail.vue'
import FormularOdoslany from '../views/FormularOdoslany.vue'
import KartaBoryMall from '../views/pravidlaSutaze/KartaBoryMall.vue'
import OdporucteBoryGdpr from '../views/pravidlaSutaze/OdporucteBoryGdpr.vue'
import OdporucteBoryPodmienky from '../views/pravidlaSutaze/OdporucteBoryPodmienky.vue'
import UaFinancovanie from '../views/UaFinancovanie.vue'
import ZnizenyUrok from '../views/ZnizenyUrok.vue'
import StanoviskoPlyn from '../views/dokumenty/stanovisko-plyn.vue'
import DefaultLayout from '../layouts/default.vue'
import RetailLayout from '../layouts/retail.vue'
import PageNotFound from '../views/404.vue'
import ZaluzieKuchyna from '../views/ZaluzieKuchyna.vue'
import ZasadySpracovania from '../views/dokumenty/zasady-spracovania.vue'
import Prieskum from '../views/dokumenty/prieskum.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: 'Vitajte vo výbornej štvrti - Bory Bývanie'}
      },
      {
        path: '/nasa-stvrt',
        name: 'Naša štvrť',
        component: Stvrt,
        meta: {title: 'Naša štvrť - Bory Bývanie'}
      },
      {
        path: '/byvanie/bory1',
        name: 'Bory Bývanie Bory 1',
        component: Bory1,
        meta: {title: 'Bory Prvé Domy - Bory Bývanie'}
      },
      {
        path: '/byvanie/bory3',
        name: 'Bory Bývanie Bory 3',
        component: Bory3,
        meta: {title: 'Bory Promenáda - Bory Bývanie'}
      },
      {
        path: '/byvanie/na-hradzi',
        name: 'Bory Bývanie Na Hrádzi',
        component: Bory4,
        meta: {title: 'Bory Na Hrádzi - Bory Bývanie'}
      },
      {
        path: '/byvanie/nadvorie',
        name: 'Bory Bývanie Nádvorie',
        component: Bory5,
        meta: {title: 'Bory Nádvorie - Bory Bývanie'}
      },
      {
        path: '/byvanie/novy-dvor',
        name: 'Bory Bývanie Nový Dvor',
        component: PriJazierku,
        meta: {title: 'Nový Dvor - Bory Bývanie'}
      },
      {
        path: '/novinky',
        name: 'Novinky',
        component: Novinky,
        meta: {title: 'Novinky - Bory Bývanie'}
      },
      {
        path: '/novinky/:id',
        name: 'NewsDetail',
        component: NewsDetail,
        meta: {title: 'Novinky - Bory Bývanie'}
      },
      {
        path: '/galeria',
        name: 'Gallery',
        component: Gallery,
        meta: {title: 'Galéria - Bory Bývanie'}
      },
      {
        path: '/kontakt',
        name: 'Contact',
        component: Contact,
        meta: {title: 'Kontakt - Bory Bývanie'}
      },
      {
        path: '/byty',
        name: 'Flats',
        component: Flats,
        meta: {title: 'Byty - Bory Bývanie'}
      },
      {
        path: '/byty/:stage/:id',
        name: 'FlatDetail',
        component: FlatDetail,
        meta: {title: 'Vitajte vo výbornej štvrti - Bory Bývanie'}
      },
      {
        path: '/formular-odoslany',
        name: 'FormularOdoslany',
        component: FormularOdoslany,
        meta: {title: 'Formulár odoslaný'}
      },
      {
        path: '/pravidla-sutaze/vyhrajte-darcekovu-kartu-bory-mall',
        name: 'KartaBoryMall',
        component: KartaBoryMall,
        meta: {title: 'Vyhrajte darčekovú kartu Bory Mall'}
      },
      {
        path: '/zasady-pouzivania-suborov-cookies',
        name: 'Cookies',
        component: Cookies,
        meta: {title: 'Zásady používania súborov cookies'}
      },
      {
        path: '/pravidla-spracuvania-osobnych-udajov',
        name: 'Gdpr',
        component: Gdpr,
        meta: {title: 'Pravidlá spracúvania osobných údajov'}
      },
      {
        path: '/stanovisko-k-regulovanej-cene-plynu',
        name: 'StanoviskoPlyn',
        component: StanoviskoPlyn,
        meta: {title: 'Stanovisko spoločnosti Bory, a.s. k regulovanej cene plynu'}
      },
      {
        path: '/odporucte-bory-pravidla-spracuvania-osobnych-udajov',
        name: 'OdporucteBoryGdpr',
        component: OdporucteBoryGdpr,
        meta: {title: 'Pravidlá spracúvania osobných údajov'}
      },
      {
        path: '/podmienky-akcie-odporucte-projekt-bory-byvanie',
        name: 'OdporucteBoryPodmienky',
        component: OdporucteBoryPodmienky,
        meta: {title: 'Podmienky akcie Odporučte projekt Bory Bývanie'}
      },
      {
        path: '/ua-financovanie',
        name: 'UaFinancovanie',
        component: UaFinancovanie,
        meta: {title: 'фінансування'}
      },
      {
        path: '/znizeny-urok',
        name: 'ZnizenyUrok',
        component: ZnizenyUrok,
        meta: {title: 'Znížený Bory úrok 3%'}
      },
      {
        path: '/zaluzie-a-kuchyna-v-cene-bytu',
        name: 'ZaluzieKuchyna',
        component: ZaluzieKuchyna,
        meta: {title: 'Žálúzie a kuchyňa v cene bytu'}
      },
      {
        path: '/zasady-spracovania-osobnych-udajov',
        name: 'ZasadySpracovania',
        component: ZasadySpracovania,
        meta: {title: 'ZÁSADY SPRACÚVANIA OSOBNÝCH ÚDAJOV'}
      },
      {
        path: '/prieskum-ohladom-druzstevneho-byvania',
        name: 'Prieskum',
        component: Prieskum,
        meta: {title: 'Prieskum ohľadom družstevného bývania'}
      }
    ]
  },
  {
    path: '/retail-zone',
    component: RetailLayout,
    children: [
      {
        path: '/retail-zone',
        name: 'RetailZone',
        component: RetailZone,
        meta: {title: 'Retail Zone - Bory Bývanie'}
      }
    ]
  },
  {
    path: '*',
    component: DefaultLayout,
    children: [
      {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {title: 'Page not found'}
      }
    ]
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ selector: to.hash,
              behavior: 'smooth' })
          }, 1000)
        })
      } else {
        return {x: 0, y: 0}
      }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to?.meta?.title
    next()
})


export default router

<template>
  <div class="bg-white">
    <FlatsFilter v-model="flatsFilter" :show-stage-and-date="showStageAndDate" :show-date="showDate" :get-stage="stages" />
    <div class="compare-button" v-if="selectedItems.length > 0" @click="scrollToCompareTable">
      <div class="d-flex align-items-center">
        <div class="mr-4">
          <img src="../assets/icons/compare.svg"> 
        </div>
        <div class="mr-4">
          <p class="mb-1">{{ $t('Porovnávanie bytov') }}</p>
          <p class="small-text mb-0">{{ $t('Pridané byty:') }} {{selectedItems.length}}</p>
        </div>
        <div>
          <a @click="scrollToCompareTable" class="more">{{ $t('Zobraziť') }}</a>
        </div>
      </div>
      <div class="compare-flats-detail border-top w-100 mt-1 pt-1 d-none d-md-block">
        <div v-for="(flat, id) in compare" :key="id" class="row align-items-center">
          <div class="col">{{ flat.flat_number_of_rooms }} {{ $t('izb.') }}</div>
          <div class="col">{{ flat.flat_penta_internal_id }}</div>
          <div class="col">{{currency(flat.flat_price)}} €</div>
          <div class="col-2 d-flex justify-content-end"><div class="d-flex align-items-center p-2" @click="deleteFromCompare(flat, false)"><img class="delete-item" width="10" src="../assets/icons/close.svg" alt=""></div></div>
        </div>
      </div>
    </div>
    <div class="flats-table">
      <b-table
          selectable
          responsive
          tabindex="row.index"
          @row-clicked="onRowSelected"
          select-mode="single"
          :items="filteredFlats(flatsFilter)"
          :fields="fields"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          ref="flatsTable"
          :tbody-tr-class="isDisabled"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
        </template>
        <template #cell(building.building_stage)="data">
          <div v-if="data.value === 'bory3'"><img width="40" src="../assets/images/gallery-slides/bory3_small.png" alt="Bory 3"></div>
          <div v-if="data.value === 'bory2'"><img width="40" src="../assets/images/gallery-slides/bory2_small.png" alt="Bory 2"></div>
          <div v-if="data.value === 'bory2b'"><img width="40" src="../assets/images/gallery-slides/bory2_small.png" alt="Bory 2"></div>
          <div v-if="data.value === 'bory4'"><img width="40" src="../assets/images/na-hradzi.png" alt="Na Hrádzi"></div>
        </template>
        <template #cell(url_link_to_the_vr_apartments_tour)="data">
          <a v-if="data.value" :href="data.value" target="_blank" class="vr-icon"><img width="34" height="20" src="../assets/icons/360.svg" alt=""><br>{{ $t('Spustiť') }}</a>
        </template>
        <template #cell(flat_area)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_area_total)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_type)="data">
          <div class="d-flex align-items-center">
            <div v-if="data.value === '1'">{{ $t('Byt') }}</div>
            <div v-if="data.value === '19'">{{ $t('Apartmán') }}</div>
            <div class="ml-1 premium-standard" v-if="(data.item.building.building_stage === 'bory2b' || data.item.building.building_stage === 'bory4') && data.item.flat_number_of_rooms == '3' || data.item.flat_number_of_rooms == '4'">
              <div class="popup-info">
                <div class="text">
                  Vyšší štandard v cene bytu
                </div>
              </div>
              <div class="icon">
                <img src="../assets/icons/premium.png">
              </div>
            </div>
          </div>
        </template>
        <template #cell(flat_area_other)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_status)="data">
          <div v-if="data.value === '0'" class="text-success font-weight-bold">{{ $t('Voľný') }}</div>
          <div v-if="data.value === '1'">{{ $t('Predrezervované') }}</div>
          <div v-if="data.value === '2'">{{ $t('Rezervované') }}</div>
          <div v-if="data.value === '3'">{{ $t('Predané') }}</div>
          <div v-if="data.value === '4'">{{ $t('Kontaktujte predajcu') }}</div>
          <div v-if="data.value === '5'">{{ $t('Kontaktujte predajcu') }}</div>
        </template>
        <template #cell(flat_price)="data">
          <div style="min-width: 200px;" class="table-price">
            <span class="font-weight-bold">{{data.value}}</span>
            <b-icon-info-circle-fill v-if="data.value.length > 0" class="ml-2" v-b-tooltip.hover.top="'Cena s DPH platnou len do konca roka 2024'"></b-icon-info-circle-fill>
            <br>
            <span class="text-small" v-if="data.value.length > 0">Len do konca roka</span>
          </div>
        </template>
        <template #cell(porovnat)="data">
          <b-form-checkbox
              v-if="data.item.flat_status === '0' || data.item.flat_status === '1'"
              @change="addToCompare($event, data)"
              v-model="selectedItems"
              :disabled="selectedItems.length >= 4 && selectedItems.indexOf(data.index) === -1"
              :value="data.item.flat_penta_internal_id"
              unchecked-value="not_accepted"
          ></b-form-checkbox>
        </template>
      </b-table>
      <p class="text-center text-dark m-5" v-if="filteredFlats(flatsFilter).length === 0">{{ $t('Vybraným kritériám nezodpovedá žiadny byt.') }}</p>
      <div class="d-flex align-items-center justify-content-center">
        <b-button v-if="perPage <= filteredFlats(flatsFilter).length" class="btn btn-primary rounded-0" @click="loadMoreFlats">{{ $t('Načítať ďalšie') }}</b-button>
      </div>
    </div>
    <div v-if="compare.length > 0" class="flats-table compare-table" id="compare-table">
      <h3 class="text-dark">{{ $t('Vybrané byty - porovnanie') }}</h3>
      <b-table
          selectable
          responsive
          @row-clicked="onRowSelected"
          select-mode="single"
          :items="compare"
          :fields="fields"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
        </template>
        <template #cell(building.building_stage)="data">
          <div v-if="data.value === 'bory3'"><img width="40" src="../assets/images/gallery-slides/bory3_small.png" alt="Bory 3"></div>
          <div v-if="data.value === 'bory2'"><img width="40" src="../assets/images/gallery-slides/bory2_small.png" alt="Bory 2"></div>
          <div v-if="data.value === 'bory2b'"><img width="40" src="../assets/images/gallery-slides/bory2_small.png" alt="Bory 2"></div>
          <div v-if="data.value === 'bory4'"><img width="40" src="../assets/images/na-hradzi.png" alt="Na Hrádzi"></div>
        </template>
        <template #cell(flat_area)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_area_total)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_type)="data">
          <div class="d-flex align-items-center">
            <div v-if="data.value === '1'">{{ $t('Byt') }}</div>
            <div v-if="data.value === '19'">{{ $t('Apartmán') }}</div>
            <div class="ml-1 premium-standard" v-if="(data.item.building.building_stage === 'bory2b' || data.item.building.building_stage === 'bory4') && data.item.flat_number_of_rooms == '3' || data.item.flat_number_of_rooms == '4'">
              <div class="popup-info">
                <div class="text">
                  Vyšší štandard v cene bytu
                </div>
              </div>
              <div class="icon">
                <img src="../assets/icons/premium.png">
              </div>
            </div>
          </div>
        </template>
        <template #cell(flat_area_other)="data">
          {{data.value}} m<sup>2</sup>
        </template>
        <template #cell(flat_status)="data">
          <div v-if="data.value === '0'" class="text-success font-weight-bold">{{ $t('Voľný') }}</div>
          <div v-if="data.value === '1'">{{ $t('Predrezervované') }}</div>
          <div v-if="data.value === '2'">{{ $t('Rezervované') }}</div>
          <div v-if="data.value === '3'">{{ $t('Predané') }}</div>
        </template>
        <template #cell(flat_price)="data">
          <span>{{data.value}}</span>
        </template>
        <template #cell(porovnat)="data">
          <b-link class="p-2" @click="deleteFromCompare(data.item, false)"><img width="14" src="../assets/icons/close.svg" alt=""></b-link>
        </template>
        <template #cell(url_link_to_the_vr_apartments_tour)="data">
          <a v-if="data.value" :href="data.value" target="_blank" class="vr-icon"><img width="34" height="20" src="../assets/icons/360.svg" alt=""><br>{{ $t('Spustiť') }}</a>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-button class="btn btn-primary rounded-0" @click="showCompareModal">{{ $t('Zobraziť porovnanie') }}</b-button>
      </div>
      <b-modal id="flatsCompareModal" size="xl" title="" centered hide-footer>
        <div class="d-flex flex-wrap">
          <b-col v-for="(flat, index) in popupFlats" :key="index" class="d-flex justify-content-between flex-column col-12 col-md mb-4 mb-md-0">
            <b-link class="remove-from-compare" @click="deleteFromCompare(flat.data.data, true)"><img width="14" src="../assets/icons/close.svg" alt=""></b-link>
            <div>
              <h3 class="text-center" v-if="flat.data.data.flat_number_of_rooms === '1' || flat.data.data.flat_number_of_rooms === '1,5'"> {{ $t('Jednoizbový byt') }} {{flat.data.data.flat_penta_internal_id}}</h3>
              <h3 class="text-center" v-if="flat.data.data.flat_number_of_rooms === '2'"> {{ $t('Dvojizbový byt') }} {{flat.data.data.flat_penta_internal_id}}</h3>
              <h3 class="text-center" v-if="flat.data.data.flat_number_of_rooms === '3'"> {{ $t('Trojizbový byt') }} {{flat.data.data.flat_penta_internal_id}}</h3>
              <h3 class="text-center" v-if="flat.data.data.flat_number_of_rooms === '4'"> {{ $t('Štvorizbový byt') }} {{flat.data.data.flat_penta_internal_id}}</h3>
              <img :src="flat.data.data.flat_plan" alt="">
              <div class="data">
                <div class="d-flex justify-content-between bg border-bottom">
                  <div class="label">{{ $t('Byt') }}</div>
                  <div class="value">{{flat.data.data.flat_penta_internal_id}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom">
                  <div class="label">{{ $t('ID bytu') }}</div>
                  <div class="value">{{flat.data.data.flat_internal_id}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom">
                  <div class="label">{{ $t('Blok') }}</div>
                  <div class="value">{{flat.data.data.building.building_name}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom">
                  <div class="label">{{ $t('Podlažie') }}</div>
                  <div class="value">{{flat.data.data.building.floor_number}}</div>
                </div>
                <div class="d-flex justify-content-between border-bottom mb-3">
                  <div class="label">{{ $t('Počet izieb') }}</div>
                  <div class="value">{{flat.data.data.flat_number_of_rooms}}</div>
                </div>

                <div class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Kúpeľňa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_bathroom1}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_bathroom2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Kúpeľňa 2') }}</div>
                  <div class="value">{{flat.data.data.flat_area_bathroom2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_balcony" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Balkón') }}</div>
                  <div class="value">{{flat.data.data.flat_area_balcony}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_bedroom" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Spálňa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_bedroom}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_corridor1" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Chodba') }}</div>
                  <div class="value">{{flat.data.data.flat_area_corridor1}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_corridor2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Chodba 2') }}</div>
                  <div class="value">{{flat.data.data.flat_area_corridor2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_front_garden" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Terasa (zelená plocha)') }}</div>
                  <div class="value">{{flat.data.data.flat_area_front_garden}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_hall" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Chodba') }}</div>
                  <div class="value">{{flat.data.data.flat_area_hall}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_kitchen" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Kuchyňa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_kitchen}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_livingroom_kitchen" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Obývacia izba + Kuchyňa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_livingroom_kitchen}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_loggia" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Loggia') }}</div>
                  <div class="value">{{flat.data.data.flat_area_loggia}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_loggia2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Loggia 2') }}</div>
                  <div class="value">{{flat.data.data.flat_area_loggia2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_garden" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Záhrada') }}</div>
                  <div class="value">{{flat.data.data.flat_area_garden}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_room1" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Izba') }}</div>
                  <div class="value">{{flat.data.data.flat_area_room1}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_room2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Izba') }} 2</div>
                  <div class="value">{{flat.data.data.flat_area_room2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_sleeping_niche" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Spací výklenok') }}</div>
                  <div class="value">{{flat.data.data.flat_area_sleeping_niche}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_storage" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Sklad') }}</div>
                  <div class="value">{{flat.data.data.flat_area_storage}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_external_storage1" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Exteriérový sklad') }}</div>
                  <div class="value">{{flat.data.data.flat_area_external_storage1}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_external_storage2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Exteriérový sklad 2') }}</div>
                  <div class="value">{{flat.data.data.flat_area_external_storage2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_terrace" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Terasa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_terrace}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_terrace2" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Terasa 2') }}</div>
                  <div class="value">{{flat.data.data.flat_area_terrace2}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_cloakroom" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('Šatňa') }}</div>
                  <div class="value">{{flat.data.data.flat_area_cloakroom}} m<sup>2</sup></div>
                </div>
                <div v-if="flat.data.data.flat_area_toilette" class="d-flex justify-content-between pl-5">
                  <div class="label">{{ $t('WC') }}</div>
                  <div class="value">{{flat.data.data.flat_area_toilette}} m<sup>2</sup></div>
                </div>
              </div>
            </div>
            <div>
              <div v-if="flat.data.data.flat_area" class="d-flex justify-content-between border-top font-weight-bold mt-3">
                <div class="label">{{ $t('ÚŽITKOVÁ PLOCHA BYTU') }}</div>
                <div class="value">{{flat.data.data.flat_area}} m<sup>2</sup></div>
              </div>
              <div v-if="flat.data.data.flat_area_total" class="d-flex justify-content-between font-weight-bold">
                <div class="label">{{ $t('ÚŽITKOVÁ PLOCHA CELKOVO') }}</div>
                <div class="value">{{flat.data.data.flat_area_total}} m<sup>2</sup></div>
              </div>

              <div v-if="flat.data.data.flat_price_before_discount_vat" class="d-flex justify-content-between font-weight-bold price mt-3">
                <div v-if="flat.data.data.flat_status === '0'" class="label">{{ $t('Cena s DPH:') }}</div>
                <div v-if="flat.data.data.flat_status === '0'" class="value">{{currency(flat.data.data.flat_price_before_discount_vat)}} €</div>
                <div v-if="flat.data.data.flat_status === '1'">{{ $t('Predrezervované') }}</div>
              </div>
            </div>
          </b-col>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <b-link class="btn btn-primary" target="_blank" :href="linkComparePdf">{{ $t('Stiahnuť PDF') }}</b-link>
        </div>
      </b-modal>
    </div>
    <div class="d-flex spinner" v-if="isLoading">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</template>

<script>
import 'vue-slider-component/theme/default.css'
import axios from "axios"
import FlatsFilter from '@/components/FlatsFilter.vue'
import { mapGetters, mapActions } from 'vuex'
import collect from 'collect.js'
import { BIcon, BIconInfoCircleFill } from 'bootstrap-vue'

function setCookie(name,value,days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export default {
  name: "FlatsTable",
  components: {
    FlatsFilter,
    BIconInfoCircleFill
  },

  computed: {
    ...mapGetters('flats', ['flats', 'filteredFlats'])
  },
  props: {
    stages: {
      required: false,
      default: () => []
    },
    showStageAndDate: {
      type: Boolean,
      required: false,
      default: true
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false
    },
    showVr: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sortBy: 'flat_status',
      sortDesc: false,
      flatsFilter: {
        stages: this.stages,
        dates: [],
        buildings: [],
        rooms: [],
        floors: [],
        availability: 0,
        priceVat: null,
        totalArea: null
      },

      isLoading: false,
      sidebarVisible: '',
      scrollPosition: null,
      selectedItems: [],
      perPage: 10,

      fields: [
        {
          key: 'building.building_stage',
          label: this.$t('Etapa'),
          sortable: true
        },
        {key: 'flat_number_of_rooms', label: this.$t('Počet izieb'), sortable: true},
        {
          key: 'flat_price',
          label: this.$t('Cena s DPH'),
          sortable: true,
          thStyle: 'min-width: 125px',
          formatter: (value, key, item) => {
            let price = parseFloat(item.flat_price)
            price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            return item.flat_status === '0' ? `${(price)} €` : ''
          }
        },
        {
          key: 'building.stage_completion_date',
          label: this.$t('Stav'),
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            if (item.flat_status == 0) {
              return 'K nasťahovaniu ihneď'
              // return value.replaceAll('.', '. ')
            } else {
              return ''
            }
          }
        },
        {
          key: 'building.building_name',
          label: this.$t('Dom'),
          sortable: true
        },
        {
          key: 'flat_type',
          label: this.$t('Typ'),
          sortable: true
        },
        {
          key: 'flat_penta_internal_id',
          label: this.$t('Číslo bytu'),
          sortable: true
        },
        {key: 'building.floor_number', label: this.$t('Podlažie'), sortable: true},
        {
          key: 'flat_area',
          label: this.$t('Výmera bytu'),
          sortable: true,
          formatter: (value, key, item) => {
            if (value)
              return value.replace('.', ',')
            else
              return '0'
          }
        },
        {
          key: 'flat_area_other',
          label: this.$t('Loggia / terasa / záhrada'),
          sortable: true,
          formatter: (value, key, item) => {
            if (value)
              return value.toString().replace('.', ',')
            else
              return '0'
          }
        },
        {
          key: 'flat_area_total',
          label: this.$t('Spolu'),
          sortable: true,
          formatter: (value, key, item) => {
            if (value)
              return value.replace('.', ',')
            else
              return '0'
          }
        },
        {key: 'flat_status', label: this.$t('Dostupnosť'), sortable: true},
        {key: this.showVr ? 'url_link_to_the_vr_apartments_tour' : '', label: 'VR', class: 'text-center'},
        {key: 'porovnat', label: this.$t('Porovnať byty')},
      ],
      items: [],
      filter: null,
      // This is much simpler if the select is it's own component.
      selectVal: {
        etapa: undefined,
        termin: undefined,
        firstName: undefined,
        lastName: undefined
      },
      compare: [],
      selected: [],
      popupFlats: [],
      images: [],
      comp: [],
      linkComparePdf: `${process.env.VUE_APP_API_URL}/api/v1/apartment-houses/compare?`
    }
  },

  async mounted() {
    this.$root.$on('setFilter', this._onSetFilter)

    
    // this.isLoading = true
    if (this.$route.query.filter) {
      const filter = JSON.parse(this.$route.query.filter)
      this._onSetFilter(filter)
    }
    await this.loadFlats()
    // this.isLoading = false
    
    if (getCookie('compareFlats')) {
      const selItems = this.selectedItems
      this.comp = JSON.parse(getCookie('compareFlats'))
      this.compare = collect(this.flats).whereIn('flat_penta_internal_id', this.comp).all()
      this.compare.forEach(element => {
        const aa = collect(this.filteredFlats(this.flatsFilter)).all()
        const indexA = aa.findIndex(p => p.flat_penta_internal_id == element.flat_penta_internal_id);
        selItems.push(element.flat_penta_internal_id)
      });
    }
    // if (getCookie('selectedFlats')) {
    //   this.selectedItems = JSON.parse(getCookie('selectedFlats'))
    // }
    
    // Docasne, kym sa nepredaju byty v 2 faze, tak ak nie je ziadny volny byt, ale predrezervovane este existuju,
    // tak ako default selectneme predrezervovane, potom toto zmazat, pripadne nechat ak bude treba
    if (this.filteredFlats(this.flatsFilter).length > 0)
      this.flatsFilter.availability = 0
    else
      this.flatsFilter.availability = 1
  },

  beforeDestroy() {
    this.$root.$off('setFilter', this._onSetFilter)
  },

  methods: {
    ...mapActions('flats', ['loadFlats']),

    scrollToCompareTable(e) {
      if (e.target.classList.contains('delete-item')) {
        return
      }
      document.querySelector('#compare-table').scrollIntoView({behavior: "smooth", block: 'center'})
    },

    isDisabled(item, type) {
      if (item.flat_status === '2' || item.flat_status === '3' || item.flat_status === '4' || item.flat_status === '5') {
        return 'row-disabled'
      }

      // if (!item || type !== 'row') return
      // if (item.status === 'awesome') return 'table-success'
    },

    currency: price => {
      let value = parseFloat(price)
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return `${value}`
    },

    showCompareModal() {
      this.isLoading = true
      const requests = [];
      let link = '';
      this.compare.forEach(function(flat){
        link += 'flats[]=' + flat.flat_penta_internal_id + '&'
        requests.push(axios.get(`${process.env.VUE_APP_API_URL}/api/v1/apartment-houses/` + flat.building.building_stage + '/' + flat.flat_penta_internal_id))
      })
      this.linkComparePdf = this.linkComparePdf + link

      axios
          .all(requests)
          .then((response) => {
            this.popupFlats = response
            this.isLoading = false
          }).then(() => {
            this.$bvModal.show('flatsCompareModal')
      })
    },

    addToCompare(isChecked, data) {
      const index = this.compare.indexOf(data.item);
      const bbb = this.compare.findIndex(p => p.flat_penta_internal_id == data.item.flat_penta_internal_id);
      if (this.comp.indexOf(data.item.flat_penta_internal_id) == -1) {
        this.compare.push(data.item)
        this.comp.push(data.item.flat_penta_internal_id)
        setCookie('compareFlats', JSON.stringify(this.comp), 1)
        setCookie('selectedFlats', JSON.stringify(this.selectedItems), 1)
      } else {
        const qwe = collect(this.selectedItems).all()
        const asd = qwe.findIndex(p => p.flat_penta_internal_id == data.item.flat_penta_internal_id);
        // this.selectedItems.splice(asd, 1)
        this.compare.splice(bbb, 1);
        this.comp.splice(this.comp.indexOf(data.item.flat_penta_internal_id), 1);
        setCookie('compareFlats', JSON.stringify(this.comp), 1)
        setCookie('selectedFlats', JSON.stringify(this.selectedItems), 1)
      }
    },
    deleteFromCompare(data, modal) {
      const aaa = this.compare.findIndex(p => p.flat_penta_internal_id == data.flat_penta_internal_id);
      const bbb = this.comp.findIndex(p => p == data.flat_penta_internal_id);
      const index = this.compare.indexOf(data);
      this.selectedItems.splice(aaa, 1)
      this.compare.splice(aaa, 1);
      this.comp.splice(bbb, 1);
      setCookie('compareFlats', JSON.stringify(this.comp), 1)
      setCookie('selectedFlats', JSON.stringify(this.selectedItems), 1)

      if (modal) {
        const popupIndex = this.popupFlats.findIndex(p => p.flat_penta_internal_id == data.flat_penta_internal_id)
        this.popupFlats.splice(popupIndex, 1)
      }
    },
    onRowSelected(items, index, event) {
      if(items.flat_status == 4 || items.flat_status == 5) {
        const contactForm = document.getElementById('footer')
        contactForm.scrollIntoView({behavior: "smooth", block: 'center'})
        return
      }
      if (!event.target.classList.contains('custom-control-label')) {
        if (items.flat_status === '0' || items.flat_status === '1') {
          this.$router.push({name:'FlatDetail', params:{id:items.flat_penta_internal_id, stage: items.building.building_stage, filter: this.flatsFilter}});
        }
      } else {
        this.$bvToast.toast(`Do porovnania môžete pridať maximálne 4 byty`, {
          title: `Porovnanie bytov`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'info',
          appendToast: true
        })
      }
    },
    loadMoreFlats() {
      this.perPage += 10;
    },

    _onSetFilter(filter) {

      this.flatsFilter = {
        ...this.flatsFilter,
        ...filter
      }
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.filter) {
        const filter = JSON.parse(this.$route.query.filter)
        this._onSetFilter(filter)

        const currentRoute = this.$router.currentRoute;
        const idToScrollTo = currentRoute.hash;
        this.$nextTick(() => {
          if (idToScrollTo && document.querySelector(idToScrollTo)) {
            document.querySelector(idToScrollTo).scrollIntoView();
          }
        });
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.premium-standard
  position: relative

  &:hover
    .popup-info
      width: 240px
      transition: all 0.3s ease

      .text
        opacity: 1
        transition: all 0.6s 0.2s ease
  .icon
    width: 36px
    height: 36px
    display: flex
    align-items: center
    justify-content: center

    img
      max-width: 100%

  .popup-info
    content: ""
    position: absolute
    z-index: 9
    top: 50%
    left: 40px
    width: 0px
    height: 60px
    transform: translateY(-50%)
    background: var(--blue)
    transition: all 0.3s 0.3s ease

    .text
      font-size: 16px
      font-weight: 600
      color: var(--dark)
      text-align: center
      line-height: 60px
      opacity: 0
      width: 240px
      transition: all 0.6s ease
      pointer-events: none

.price-bubble
  background: var(--primary)
  width: 60px
  height: 60px
  display: inline-block
  border-radius: 50%
  color: #fff
  padding: 5px
  display: flex
  flex: none
  align-items: center
  margin-left: 20px
  font-size: 11px

.table-price
  color: var(--primary)

  .text-small
    font-size: 12px

.vr-icon
  color: #001F33
  font-size: 12px
  display: block
.compare-button
  background: #DCF2FF
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2)
  position: fixed
  right: 60px
  bottom: 80px
  z-index: 999
  cursor: pointer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 15px 25px
  color: #001F33

  @media (max-width: $md)
    right: auto
    left: 0
    bottom: 0
    width: 100%

  .small-text
    font-size: 16px

  a.more
    color: #001F33
    padding-right: 20px
    position: relative

    &:after
      content: ""
      width: 10px
      height: 14px
      background: url('../assets/icons/arr-dark.svg') no-repeat
      background-size: contain
      position: absolute
      right: 0
      top: 5px

  .compareNum
    background: var(--blue)
    position: absolute
    right: -6px
    top: -6px
    color: #ffffff
    width: 24px
    height: 24px
    border-radius: 50%
    text-align: center
.price
  background: var(--textDark)
  color: #fff
  padding: 5px 10px
  font-size: 20px
.flats-table
  padding: 50px 100px
  position: relative
  max-width: 100%
  // overflow: hidden

  thead
    position: sticky
  @media (max-width: $md)
    padding: 15px
.compare-flats-detail
  font-size: 14px

.remove-from-compare
  @media (max-width: $md)
    position: absolute
    right: 0
    top: 5px
</style>
